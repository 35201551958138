import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import ContentWrapper from '../ContentWrapper'

import * as styles from './style.module.sass'

const Partners = ({ inlineMode = false }) => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { componentKey: { eq: "partners" } }) {
        frontmatter {
          title
          partners {
            name
            logo {
              publicURL
            }
          }
        }
      }
    }
  `)
  const { title, partners } = data.markdownRemark.frontmatter

  return (
      <ContentWrapper className={inlineMode ? styles.inlineMode : ''}>
        <div className={styles.container}>
          <h2 className={styles.title}><ReactMarkdown>{title}</ReactMarkdown></h2>

          <div className={`${styles.logoContainer} columns`}>
            {
              partners.map((partner, i) => (
                <img key={i} className={`${styles.logo} column is-one-third-mobile`} src={partner.logo.publicURL} alt={partner.name} width='124' height='76' />
              ))
            }
          </div>
        </div>
      </ContentWrapper>
  )
}

export default Partners
